<template>
  <div>
    <div v-if='dataList' class='sub-message-list'>
      <div v-for='(item,index) in dataList' :key='index' class=''>
        <span>【{{item.status}}】{{ item.time || '' }} {{item.location||''}}</span>
        <br/>
        <span>{{ item.context || '' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubMessage',
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    dataList() {
      // JS 反序列化（Deserialization）:

      let data = this.data ? JSON.parse(this.data.param) : {}
      let lastResult = typeof data === 'object' && data.lastResult ? data.lastResult : null

      return lastResult && lastResult.data && typeof lastResult.data == 'string' ? JSON.parse(lastResult.data) : lastResult.data
    }
  }
}
</script>

<style scoped>
.sub-message-list{
  height: 200px;
  overflow-y: scroll;
}
</style>